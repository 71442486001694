import tw from "twin.macro";
import { useEffect, useRef, useState } from "react";
import { createSearchParams, useLocation, useOutletContext } from "react-router-dom";

const Iframe = tw.iframe`m-auto my-8 w-full `;

const Payments = () => {
    const location = useLocation();
    const frameRef = useRef(null);
    const { barRef } = useOutletContext();

    const [dynamicHeight, setDynamicHeight] = useState(null);

    const { amount, guests, bookedGuests, leadBookedGuest, bookingId, bookingReference } = location.state;
    const successParams = createSearchParams({
        leadBookedGuestId: leadBookedGuest.id,
        amount,
        bookingId,
        barRef,
        depositRequired: JSON.stringify(bookedGuests.map(bg => +bg["deposit-required"])),
        bookedGuestIds: JSON.stringify(bookedGuests.map(bg => +bg.id)),
    });

    const leadGuest = guests.find(guest => guest["lead-guest"] === true);

    const queryParams = createSearchParams({
        amount,
        description: `Payment for booking Life's and adventure for ${location.state.eventDetails.name}. Booking reference:- ${bookingReference}`,
        amount_editable: false,
        success_url:
            process.env.NODE_ENV === "development"
                ? `http://localhost:3000/success?${successParams}`
                : `${process.env.REACT_APP_SUCCESS_URL}/success?${successParams}`,
        email: leadGuest["email-address"],
    });

    const url =
        process.env.NODE_ENV === "development"
            ? `https://pay.pinpayments.com/rlvl/sc/test?${queryParams}`
            : `${process.env.REACT_APP_PAYMENT_URL}?${queryParams}`;

    useEffect(() => {
        window.addEventListener("message", function (event) {
            if (!event || !event.data || !event.data.channel) return;
            setDynamicHeight(event.data.blockSize);
            barRef.current.updateToNext();
            // const { channel, processCompleted } = event.data;
            // if (channel === "payment-iframe" && processCompleted === true) {
            //     barRef.current.updateToNext();
            // }
        });
    }, [barRef]);

    useEffect(() => {
        setDynamicHeight(null);
        return () => setDynamicHeight(null);
    }, []);

    return (
        <Iframe style={{ overflow: "hidden" }} ref={frameRef} src={url} height={dynamicHeight || 1200} scrolling="no" />
    );
};

export default Payments;
