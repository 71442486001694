/** @jsxImportSource @emotion/react */
// eslint-disable-next-line
import tw from "twin.macro";
import { format } from "date-fns";
import Rating from "react-rating";
import { FaRegStar, FaStar } from "react-icons/fa";
import { IoChevronBackCircle, IoChevronForwardCircle } from "react-icons/io5";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Navigation } from "swiper";

import "swiper/swiper-bundle.min.css";
import "./Swiper.css";

import { useCustomerTestimonials } from "../api/tours.api";
import Loader from "./Loader";
import useHeightTrim from "../Utility/useHeightTrim";
import { useSearchParams } from "react-router-dom";

const CenterDiv = tw.div`mt-20 flex items-center justify-center`;

SwiperCore.use([Autoplay, Navigation]);

const ReviewDisplay = ({ children }) => {
    const {
        trimContainerRef,
        shouldTrim: shouldTrimTarget,
        isTrimmed: isTrimmedTarget,
        toggleTrim: toggleTrimTarget,
        trimmedHeight: trimmedHeightTarget,
    } = useHeightTrim({ effectHeight: 250, trimmedHeight: 180 });

    const toggleShowMoreTarget = e => {
        e.stopPropagation();
        toggleTrimTarget();
    };

    return (
        <div>
            <p
                ref={trimContainerRef}
                tw="text-left text-base font-light tracking-wide focus-within:overflow-ellipsis overflow-hidden"
                style={shouldTrimTarget && isTrimmedTarget ? { height: trimmedHeightTarget, overflow: "hidden" } : {}}
            >
                {children}
            </p>
            {shouldTrimTarget && (
                <button
                    onClick={toggleShowMoreTarget}
                    tw="font-semibold text-left cursor-pointer text-sm text-LAA-primary-dim border-0 p-0 leading-none"
                >
                    {isTrimmedTarget ? "Show more" : "Show less"}
                </button>
            )}
        </div>
    );
};

const Reviews = () => {
    const [searchParams] = useSearchParams();

    const url = searchParams.get("url");

    const { isLoading, isError, data } = useCustomerTestimonials({
        url,
    });

    if (isError) {
        return (
            <CenterDiv>
                <h1 tw="uppercase text-xl pt-4 px-6 font-medium text-center">No reviews to show</h1>
            </CenterDiv>
        );
    }

    if (isLoading) {
        return (
            <CenterDiv>
                <Loader />
            </CenterDiv>
        );
    }

    const trimmedTestimonials = data.slice(0, 6);
    return (
        <div tw="grid grid-cols-1 gap-4 items-center p-4">
            <div>
                <h1 tw="uppercase text-xl pt-4 px-6 font-medium text-center">What our customers have to say</h1>
            </div>
            <div>
                <div tw="hidden sm:block relative px-12" className="desktop-carousel">
                    <Swiper
                        navigation={{
                            nextEl: ".swiper-button-next",
                            prevEl: ".swiper-button-prev",
                        }}
                        spaceBetween={15}
                        slidesPerView={3}
                        pagination={{ clickable: true }}
                        autoplay={{ delay: 2500, pauseOnMouseEnter: true, disableOnInteraction: false }}
                        grabCursor
                        loop
                    >
                        {trimmedTestimonials.map(testimony => (
                            <SwiperSlide key={testimony.id}>
                                <div tw="p-4 flex gap-4 w-full min-h-[160px]">
                                    <div tw="flex w-12 h-12 text-2xl font-bold items-center justify-center uppercase rounded-sm bg-gradient-to-br from-gray-200 to-gray-300 border border-gray-300">
                                        {testimony.guest[0].value[0]}
                                    </div>
                                    <div tw="flex flex-col gap-3 w-full">
                                        <div tw="flex justify-between">
                                            <div>
                                                <div tw="text-base font-semibold capitalize">
                                                    {testimony.guest[0].value}
                                                </div>
                                                <div tw="text-gray-400 font-medium text-base">
                                                    {format(new Date(testimony.createdAt), "d MMMM yyyy")}
                                                </div>
                                            </div>
                                            <div tw="pt-1 leading-none">
                                                <Rating
                                                    initialRating={+testimony["overall-tour-rating"]}
                                                    emptySymbol={<FaRegStar color="#62ba38" />}
                                                    fullSymbol={<FaStar color="#62ba38" />}
                                                    start={0}
                                                    stop={10}
                                                    step={2}
                                                    readonly
                                                />
                                            </div>
                                        </div>
                                        <ReviewDisplay>{testimony["overall-tour-feedback"]}</ReviewDisplay>
                                        <div tw="flex justify-end mt-4">
                                            <span tw="italic font-semibold">
                                                {testimony.tour[0]?.value?.split(" - ")?.[0]}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                    <div
                        className="swiper-button-next"
                        tw="after:content-[''] right-0 inset-y-0 flex items-center justify-center h-full my-0 bg-white w-auto pl-2"
                    >
                        <IoChevronForwardCircle size={40} />
                    </div>
                    <div
                        className="swiper-button-prev"
                        tw="after:content-[''] left-0 inset-y-0 flex items-center justify-center h-full my-0 bg-white w-auto pr-2"
                    >
                        <IoChevronBackCircle size={40} />
                    </div>
                </div>
                <div tw="block sm:hidden" className="mobile-carousel">
                    <Swiper
                        direction="horizontal"
                        spaceBetween={15}
                        slidesPerView="auto"
                        pagination={{ clickable: true }}
                        autoplay={{ delay: 2500, pauseOnMouseEnter: true, disableOnInteraction: false }}
                        centeredSlides
                        grabCursor
                        loop
                    >
                        {trimmedTestimonials.map(testimony => (
                            <SwiperSlide key={testimony.id}>
                                <div tw="p-2 flex gap-3 flex-col justify-center">
                                    <div tw="flex w-12 h-12 text-2xl font-bold items-center justify-center uppercase rounded-sm bg-gradient-to-br from-gray-200 to-gray-300 border border-gray-300">
                                        {testimony.guest[0].value[0]}
                                    </div>
                                    <div tw="pt-1 leading-none">
                                        <Rating
                                            initialRating={+testimony["overall-tour-rating"]}
                                            emptySymbol={<FaRegStar color="#62ba38" />}
                                            fullSymbol={<FaStar color="#62ba38" />}
                                            start={0}
                                            stop={10}
                                            step={2}
                                            readonly
                                        />
                                    </div>
                                    <div>
                                        <div tw="text-base font-semibold capitalize">{testimony.guest[0].value}</div>
                                        <div tw="text-gray-400 font-medium text-base">
                                            {format(new Date(testimony.createdAt), "d MMMM yyyy")}
                                        </div>
                                    </div>
                                    <ReviewDisplay>{testimony["overall-tour-feedback"]}</ReviewDisplay>
                                    <div tw="flex justify-center mt-4">
                                        <span tw="italic font-semibold">
                                            {testimony.tour[0]?.value?.split(" - ")?.[0]}
                                        </span>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </div>
        </div>
    );
};

export default Reviews;
